
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import clients_img from '../../assets/images/clients.jpg'
import projects_img from '../../assets/images/projects.jpg'
import ModalVideo from 'react-modal-video'

export default class About1 extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal2 = this.openModal2.bind(this)
    }

    openModal2() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="about-st1-grid">
                        <div className="about-left">
                            <h1 className="about-left-title">
                                We Are Trusted by More Than <strong>8 Clients.</strong>
                            </h1>
                            <p className="about-left-desc">
                            Being trusted by clients is a significant accomplishment for any business. It indicates that the company has established a strong reputation for delivering high-quality services, meeting or exceeding client expectations, and maintaining excellent customer relationships.

At our consulting firm, we take great pride in earning our clients' trust through our commitment to excellence, transparency, and accountability. We prioritize understanding our clients' unique needs and goals, and we work collaboratively to design and implement tailored solutions that deliver measurable results.

Our clients trust us because we prioritize their success, maintain open and honest communication, and consistently deliver exceptional service. We are committed to building long-term relationships with our clients and earning their trust every step of the way.
                            </p>
                            <Link to="/#" className="theme-button">
                                Join Now <FiChevronRight className="icon" />
                            </Link>
                        </div>
                        <div className="about-right">
                            <img className="about-right-img" src={clients_img} alt="About Video" />
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ddcsiNSmdXA' onClose={() => this.setState({ isOpen: false })} />
                            <div className="video-button-box">
                                <Link to="/#" className="video-button" onClick={this.openModal2}>
                                    <span className="icon"><FiPlay /></span>
                                    Watch Video
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img" src={projects_img} alt="History" />
                            </div>
                            <div className="history-details-box">
                                <h1 className="history-details-title">
                                    More Than <strong>12 Projects </strong>
                                    Were Completed.
                                </h1>
                                <p className="history-details-desc">
                                Completing more than 12 projects in one year is a significant achievement for any consulting firm. It demonstrates a high level of efficiency, productivity, and organization, as well as the ability to deliver quality results on time and within budget.

At our consulting firm, we are proud to have completed more than 12 successful projects in ,
                                </p>
                                <Link to="/#" className="theme-button">
                                    Get Started <FiChevronRight className="icon" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
