import React  from 'react'
import { FiPhone, FiMapPin, FiChevronDown } from 'react-icons/fi'
import { FaEnvelope } from 'react-icons/fa'
import flat1 from '../../assets/images/flag.jpg'
import sectiondata from "../../store/store";
import {Link} from "react-router-dom";

export default function HeaderBar() {
    return (
        <div className="header-bar-wrapper">
            <div className="container">
                <div className="header-bar-grid">
                    <div className="header-bar-left">
                        <ul className="d-flex">
                            {
                                sectiondata.HeaderData.HeaderBar.phoneNumber ?
                                    <li className="d-flex"><FiPhone className="icon" /> <a style={{minWidth:"100px"}} href={'tel:'+sectiondata.HeaderData.HeaderBar.phoneNumber}>{sectiondata.HeaderData.HeaderBar.phoneNumber}</a></li>
                                : ''
                            }
                            {
                                sectiondata.HeaderData.HeaderBar.email ?
                                    <li className="d-flex"><FaEnvelope className="icon" /> <a href={"mailto:"+sectiondata.HeaderData.HeaderBar.email}>{sectiondata.HeaderData.HeaderBar.email}</a></li>
                                : ''
                            }
                            {
                                sectiondata.HeaderData.HeaderBar.location ?
                                    <li className="d-flex"><FiMapPin className="icon" /> {sectiondata.HeaderData.HeaderBar.location}</li>
                                : ''
                            }
                        </ul>
                    </div>
                    <div className="header-bar-right text-right">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
